// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_sJ d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_sK d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_sL d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_sM d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_sN d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_sP d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_sQ d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_rk q_rk";
export var heroExceptionNormal = "t_rl q_rl";
export var heroExceptionLarge = "t_rm q_rm";
export var Title1Small = "t_qX q_qX q_qr q_qs";
export var Title1Normal = "t_qY q_qY q_qr q_qt";
export var Title1Large = "t_qZ q_qZ q_qr q_qv";
export var BodySmall = "t_q9 q_q9 q_qr q_qL";
export var BodyNormal = "t_rb q_rb q_qr q_qM";
export var BodyLarge = "t_rc q_rc q_qr q_qN";