// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_sh d_fp d_bG d_dv";
export var alignLeft = "s_qh d_fp d_bG d_dv";
export var alignDiscCenter = "s_sj d_fq d_bD d_dw";
export var alignCenter = "s_bP d_fq d_bD d_dw";
export var alignDiscRight = "s_sk d_fr d_bH d_dx";
export var alignRight = "s_qj d_fr d_bH d_dx";
export var footerContainer = "s_sl d_dW d_bW";
export var footerContainerFull = "s_sm d_dT d_bW";
export var footerHeader = "s_kf d_kf";
export var footerTextWrapper = "s_sn d_w";
export var footerDisclaimerWrapper = "s_km d_km d_ck";
export var badgeWrapper = "s_sp d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "s_sq d_bz d_bJ d_bN d_bL";
export var wide = "s_sr d_cy";
export var right = "s_ss d_bK";
export var line = "s_fk d_fl d_cv";
export var badgeDisclaimer = "s_st d_bC d_bP d_bJ";
export var badgeContainer = "s_sv d_bz d_bH d_bP";
export var badge = "s_sw";
export var padding = "s_sx d_c7";
export var end = "s_sy d_bH";
export var linkWrapper = "s_sz d_dB";
export var link = "s_mC d_dB";
export var colWrapper = "s_sB d_cx";
export var consent = "s_f d_f d_bC d_bP";
export var disclaimer = "s_sC d_bz d_bJ";
export var media = "s_sD d_bx d_dy";
export var itemRight = "s_sF";
export var itemLeft = "s_sG";
export var itemCenter = "s_sH";
export var exceptionWeight = "s_qp q_qT";